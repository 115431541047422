import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Zestechno | Creative & Strategic Digital Solutions Agency</title>
                <meta name="description" content="Multi-channel marketing approach focused on INCREASING CONVERSIONS, REPEAT TRAFFIC and generating greater BRAND LOYALTY" />
                <meta name="og:title" property="og:title" content="Zestechno | Creative & Strategic Digital Solutions Agency"></meta>
                <meta name="twitter:card" content="Multi-channel marketing approach focused on INCREASING CONVERSIONS, REPEAT TRAFFIC and generating greater BRAND LOYALTY"></meta>
                <link rel="canonical" href="https://zestechno.com/"></link>
                <meta property="og:image" content="https://zestechno.com/favicon.png" />
            </Helmet>
        </div>
    )
}

export default SEO
