import React from "react";
import { Link } from "gatsby";
import logo from "../../assets/images/logo.svg";
import footerMap from "../../assets/images/footer-map.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>
                Experienced in our team of Zestechno supports you to improve
                your business visibility online through various digital
                marketing techniques such as SEO,SEM,SMO,SMM,Email Marketing
                etc. We ensure your business growth organically and get the
                proven results.
              </p>

              <ul className="social-link">
                <li>
                  <Link
                    to="https://www.facebook.com/zestechno/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://twitter.com/zestechno"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/zestechnozestechno/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/in/zestechno/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Explore</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  {/* <Link to="/about-us">About</Link> */}
                  About
                </li>
                <li>
                  {/* <Link to="/case-studies">Case Studies</Link> */}
                  Case Studies
                </li>
                <li>
                  {/* <Link to="/blog">Our Blog</Link> */}
                  Our Blog
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Resources</h3>

              <ul className="footer-links-list">
                <li>
                  {/* <Link to="/team">Our Scientists</Link> */}
                  Digital Marketing
                </li>
                <li>
                  {/* <Link to="/services">Our Services</Link> */}
                  Inbound Marketing
                </li>
                <li>
                  {/* <Link to="/testimonials">Testimonials</Link> */}
                  App & Web Development
                </li>
                <li>
                  {/* <Link to="/services">SaaS Solutions</Link> */}
                  UI/UX Design
                </li>
                <li>
                  {/* <Link to="/case-studies">Case Studies</Link> */}
                  Branding
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  New 9 Old 4/2 Vasuki Street,
                  <br />
                  Thiruvalluvar Nagar, Arumbakkam,
                  <br />
                  Chennai, Tamil Nadu 600106
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+44587154756">+91 9840-109-843</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:hi@zestechno.com">hi@zestechno.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-md-12">
              <p className="text-center">
                Copyright @{currentYear} <strong>Zestechno</strong> All rights
                reserved
              </p>
            </div>
            {/* <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Zestechno</strong> All rights reserved</p>
                        </div> */}

            {/* <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                     */}
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  );
};

export default Footer;
